// import ButtonAppBar from "./Component/ResponsiveAppBar";

import {BrowserRouter as Router, Route, Routes, Navigate , Outlet } from 'react-router-dom';
import ResponsiveBar from "./Component/ButtonAppBar";
import Kgr from "./Pages/Kgr";
// import PaidKgr from "./Pages/PaidKgr";
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import { Box } from '@mui/material/';
// import GoogleAutoSuggest from './Pages/GoogleAutoSuggest';
// import Contact from './Pages/Contact';
// import Allintitle from './Pages/Allintitle';
import TawkTo from 'tawkto-react';
import React, {useState, useEffect} from 'react';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
// import PaidSuperFastKGR from './Pages/PaidSuperFastKGR';
// import Validate from './Pages/Validate';
import Footer from "./Component/Footer";
// import ProtectedRoutes from "./ProtectedRoutes";



const theme = createTheme({
  palette: {
    primary: {
      main: '#FF00DF',
    },
  },
});


const PrivateRoutes = () => {
  const [tokens, setTokens] = useState({ token: true });

  useEffect(() => {
    const tawkans = localStorage.getItem("extension");
    if (tawkans && tawkans.length !== 0) {
      setTokens({ token: true  });
      // console.log(tawk)
     
    } else {
      setTokens({ token: false  });
     
    }
    // console.log(tawk)
    
  }, []);

  return tokens.token ? <Outlet /> : <Navigate to='/signup' />;
};


function App() {
  
  const [dataas, setDataas] = useState({ token: false })

  // const [isLogged, setIsLogged] = useState(false);
  // const initializeState = () =>  !(localStorage.getItem("tawk"));

  // const [tokens, setTokens] = useState(initializeState);



  

useEffect(() => {

   

  var tawk = new TawkTo('62ab69c17b967b117994ecb6', '1g5mpa8fp')

  tawk.onStatusChange((status) => 
  {
      // console.log(status)
  })

  // if (localStorage.getItem("tawk") === null) {
  //   setTokens(false);
  // } else {
  //   setTokens(true);
  // }

  const tawkans = localStorage.getItem("extension");
  if (tawkans && tawkans.length !== 0) {
    setDataas({ token: true });
  } else {
    setDataas({ token: false });
  }



}, []);


// const token = localStorage.getItem('token');

// useEffect(() => {

//   if(token && token.length > 8){
   
//     setIsLogged(true)
//   }
// }, [])



//   



  return (
    <ThemeProvider theme={theme}>
    <Router>
    <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'space-between', maxHeight: 'md'  }} className="bigContainer">
      <Box>
      {/* <ResponsiveBar tokens={tokens} /> */}
      <ResponsiveBar tokens={dataas.token} />
        {/* <ButtonAppBar  /> */}
        </Box>
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
        <Routes>
          {/* <Route exact path="/" element={< Kgr />} /> */}
          {/* <Route exact path="/" element={tokens ?  <PaidKgr /> : <Kgr /> } />  */}
          <Route element={<PrivateRoutes/>}>
          {/* <Route path="/" element={tokens ? < /> : <Navigate to="/signup" />} />  */}
          <Route path="/" element={ <Kgr /> } /> 
          </Route>
           {/* <Route path="/youtube-auto-complete" element={<GoogleAutoSuggest />} /> */}
           {/* <Route path="/contact" element={<Contact />} /> */}
           {/* <Route path="/premium" element={<PaidKgr />} /> */}

           <Route path="/login" element={<Login />} />
           <Route path="/signup" element={<SignUp />} />
           {/* <Route path="/newkgr" element={<SuperFastKGR />} /> */}
           {/* <Route path="/newpaidkgr" element={< />} /> */}
           {/* <Route path="/people-also-ask" element={<PeopleAlsoAsk />} />
           <Route path="/google-suggestion" element={<GoogleSuggestion />} /> */}
           
          
           
           {/* {/* <Route path="/bulk-website-pages-index-checker" element={tokens ? <IndexChecker /> : <Navigate to="/signup" />} />  */}
           {/* <Route path="/bulk-link-index-checker" element={tokens ? <BulkPageIndexChecker /> : <Navigate to="/signup" />} />  */}
           {/* <Route path="/google-scraper" element={tokens ? <GoogleScraper /> : <Navigate to="/signup" />} /> 
           <Route path="/bing-scraper" element={tokens ? <BingScraper /> : <Navigate to="/signup" />} /> */} */}
           {/* <Route path="/quora-question-finder" element={tokens ? <GoogleQuora /> : <Navigate to="/signup" />} /> */}
           {/* <Route path="/pricing" element={tokens ? <Pricing /> : <Navigate to="/signup" />} /> */}
           {/* <Route path="/bulk-link-indexer" element={tokens ? <BulkIndexer /> : <Navigate to="/signup" />} /> */}
           {/* <Route path="/reddit-topic-hunter" element={tokens ? <RedditTool /> : <Navigate to="/signup" />} /> */}
           {/* <Route path="/reddit-topic-hunter" element={<RedditTool />} /> */}
           {/* <Route path="/seo" element={<Seo />} /> */}
           {/* <Route path="/quora" element={<Quora />} /> */}
           
         
           {/* <Route path="/keyword-builder" element={<KeywordBuilder />} /> */}

           {/* <Route path="/bulk-website-pages-index-checker" element={<IndexChecker />} />
           <Route path="/bulk-pages-index-checker" element={<BulkPageIndexChecker />} />
           <Route path="/google-scraper" element={<GoogleScraper />} />
           <Route path="/bing-scraper" element={<BingScraper />} /> */}
          {/* <Route path="/pricing" element={<Pricing />} /> */}
      
        </Routes>
        {/* </Routes> */}
        </Box>
      <Box>
        <Footer />
        
        </Box>
        
        </Box>
      
        </Router>
        </ThemeProvider>
  );
}

export default App;
